/* eslint-disable no-nested-ternary */
import { capitalize } from './stringUtils';

export const getMyInfosStatus = (myInfos, info, returnType = 'number') => {
  if (myInfos !== null) {
    let totalOfItems = 0;
    let itemsFilled = 0;
    if (info === 'aboutGlaucoma') {
      totalOfItems = 9;

      if (myInfos.aboutGlaucoma.withEyes !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutGlaucoma.timeWithGlaucoma !== null) {
        itemsFilled++;
      }
      if (
        myInfos.aboutGlaucoma.treatmentsToGlaucoma &&
        myInfos.aboutGlaucoma.treatmentsToGlaucoma.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutGlaucoma.impairedVision &&
        myInfos.aboutGlaucoma.impairedVision.length > 0
      ) {
        itemsFilled++;
      }
      if (myInfos.aboutGlaucoma.budgetImpact !== null) {
        itemsFilled++;
      }
      if (
        myInfos.aboutGlaucoma.treatmentDifficulty &&
        myInfos.aboutGlaucoma.treatmentDifficulty.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutGlaucoma.oldTreatmentsToGlaucoma &&
        myInfos.aboutGlaucoma.oldTreatmentsToGlaucoma.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutGlaucoma.familiesWithGlaucoma &&
        myInfos.aboutGlaucoma.familiesWithGlaucoma.length > 0
      ) {
        itemsFilled++;
      }
      if (myInfos.aboutGlaucoma.fearWorry !== null) {
        itemsFilled++;
      }
    } else if (info === 'aboutOlhoSeco') {
      totalOfItems = 14;

      if (myInfos.aboutOlhoSeco.withEyes !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutOlhoSeco.timeWithOlhoSeco !== null) {
        itemsFilled++;
      }
      if (
        myInfos.aboutOlhoSeco.treatmentsToOlhoSeco &&
        myInfos.aboutOlhoSeco.treatmentsToOlhoSeco.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutOlhoSeco.treatmentDifficulty &&
        myInfos.aboutOlhoSeco.treatmentDifficulty.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutOlhoSeco.oldTreatmentsToOlhoSeco &&
        myInfos.aboutOlhoSeco.oldTreatmentsToOlhoSeco.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutOlhoSeco.familiesWithOlhoSeco &&
        myInfos.aboutOlhoSeco.familiesWithOlhoSeco.length > 0
      ) {
        itemsFilled++;
      }
      if (myInfos.aboutOlhoSeco.haveEyeSurgery !== null) {
        itemsFilled++;
      }
      if (
        myInfos.aboutOlhoSeco.useSomeMedicine &&
        myInfos.aboutOlhoSeco.useSomeMedicine.length > 0
      ) {
        itemsFilled++;
      }
      if (myInfos.aboutOlhoSeco.treatmentHasEffect !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutOlhoSeco.hasDoctorMonitoringTreatment !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutOlhoSeco.monthlyIncome !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutOlhoSeco.treatmentMonthlyExpense !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutOlhoSeco.isTreatmentImportant !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutOlhoSeco.fearWorry !== null) {
        itemsFilled++;
      }
    } else if (info === 'aboutDmri') {
      totalOfItems = 16;

      if (myInfos.aboutDmri.withEyes !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.timeWitDmri !== null) {
        itemsFilled++;
      }
      if (
        myInfos.aboutDmri.treatmentsToDmri &&
        myInfos.aboutDmri.treatmentsToDmri.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutDmri.treatmentDifficulty &&
        myInfos.aboutDmri.treatmentDifficulty.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutDmri.oldTreatmentsToDmri &&
        myInfos.aboutDmri.oldTreatmentsToDmri.length > 0
      ) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.lastTimeInOphthalmologist !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.lastOCTexam !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.monitoringTelaDeAmsler !== null) {
        itemsFilled++;
      }
      if (
        myInfos.aboutDmri.treatmentSupplement &&
        myInfos.aboutDmri.treatmentSupplement.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutDmri.oldTreatmentSupplement &&
        myInfos.aboutDmri.oldTreatmentSupplement.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.aboutDmri.familiesWithDmri &&
        myInfos.aboutDmri.familiesWithDmri.length > 0
      ) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.hasDoctorMonitoringTreatment !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.monthlyIncome !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.treatmentMonthlyExpense !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.isTreatmentImportant !== null) {
        itemsFilled++;
      }
      if (myInfos.aboutDmri.fearWorry !== null) {
        itemsFilled++;
      }
    } else if (info === 'aboutGenericDiseasesTreatments') {
      totalOfItems = 9;

      if (myInfos.withEyes !== null) {
        itemsFilled++;
      }
      if (myInfos.timeWithDisease !== null) {
        itemsFilled++;
      }
      if (
        myInfos.treatmentDifficulty &&
        myInfos.treatmentDifficulty.length > 0
      ) {
        itemsFilled++;
      }
      if (
        myInfos.familiesWithDisease &&
        myInfos.familiesWithDisease.length > 0
      ) {
        itemsFilled++;
      }
      if (myInfos.hasDoctorMonitoringTreatment !== null) {
        itemsFilled++;
      }
      if (myInfos.monthlyIncome !== null) {
        itemsFilled++;
      }
      if (myInfos.treatmentMonthlyExpense !== null) {
        itemsFilled++;
      }
      if (myInfos.isTreatmentImportant !== null) {
        itemsFilled++;
      }
      if (myInfos.fearWorry !== null) {
        itemsFilled++;
      }
    } else if (info === 'personalInfo') {
      totalOfItems = 1;

      itemsFilled++;
    } else if (info === 'otherEyeDiseases') {
      totalOfItems = 1;

      if (myInfos.otherEyeDiseases.hasOtherEyeDiseases !== null) {
        itemsFilled++;
      }
    } else if (info === 'otherEyeProcedures') {
      totalOfItems = 1;

      if (myInfos.otherEyeProcedures.didOtherEyeProcedures !== null) {
        itemsFilled++;
      }
    } else if (info === 'eyeDropAllergies') {
      totalOfItems = 1;

      if (myInfos?.eyeDropAllergies?.eyeDropAllergies) {
        itemsFilled++;
      }
    } else if (info === 'habitsOfLife') {
      totalOfItems = 8;

      if (myInfos.habitsOfLife.bodyWeight !== null) {
        itemsFilled++;
      }
      if (myInfos.habitsOfLife.bodyHeight !== null) {
        itemsFilled++;
      }
      if (myInfos.habitsOfLife.physicalExercise !== null) {
        itemsFilled++;
      }
      if (myInfos.habitsOfLife.drinkCoffee !== null) {
        itemsFilled++;
      }
      if (myInfos.habitsOfLife.drinkAlcohol !== null) {
        itemsFilled++;
      }
      if (myInfos.habitsOfLife.smokeCigarettes !== null) {
        itemsFilled++;
      }
      if (myInfos.habitsOfLife.useWeeds !== null) {
        itemsFilled++;
      }
      if (myInfos.habitsOfLife.sleepOver6Hours !== null) {
        itemsFilled++;
      }
    } else if (info === 'medicationEveryDay') {
      totalOfItems = 1;

      if (myInfos.medicationEveryDay.hasOtherMedicationEveryDay !== null) {
        itemsFilled++;
      }
    } else if (info === 'otherDiseases') {
      totalOfItems = 1;

      if (myInfos.otherDiseases.hasOtherDiseases !== null) {
        itemsFilled++;
      }
    } else if (info === 'useOfGlasses') {
      totalOfItems = 1;

      if (myInfos.useOfGlasses.useGlasses !== null) {
        itemsFilled++;
      }
    }

    if (returnType === 'number') {
      return `${itemsFilled} / ${totalOfItems}`;
    }
    if (returnType === 'percentage') {
      return Math.round((itemsFilled / totalOfItems) * 100).toString();
    }
    if (returnType === 'boolean') {
      return itemsFilled === totalOfItems;
    }
    if (returnType === 'class') {
      return itemsFilled === totalOfItems
        ? 'success'
        : itemsFilled > 0
          ? 'warning'
          : '';
    }
  }
};

export const convertBudgetImpactToText = budgetImpact => {
  return {
    1: 'Pouco',
    2: 'Médio',
    3: 'Médio para muito'
  }[budgetImpact] || 'Muito';
};

export const getQuestionAndAnswersAboutGlaucoma = myInfos => {
  if (!myInfos) return []
  let eyes = 'Ambos'
  if (myInfos.withEyes === 'left') {
    eyes = 'Esquerdo'
  } else if (myInfos.withEyes === 'right') {
    eyes = 'Direito'
  }
  return [
    {
      label: 'Quais olhos?',
      value: eyes,
    },
    {
      label: 'Há quanto tempo',
      value: myInfos.timeWithGlaucoma || 'Sem resposta',
    },
    {
      label: 'Familiares com Glaucoma',
      value: myInfos.familiesWithGlaucoma.length > 0 ? capitalize(
        myInfos.familiesWithGlaucoma.join(', ').toLocaleLowerCase()) : 'Sem resposta',
    },
    {
      label: 'Tratamentos',
      value: myInfos.treatmentsToGlaucoma?.length > 0 ? capitalize(myInfos.treatmentsToGlaucoma.join(', ').toLocaleLowerCase()) : 'Sem resposta',
    },
    {
      label: 'Tratamentos passados',
      value: myInfos.oldTreatmentsToGlaucoma?.length > 0 ? capitalize(myInfos.oldTreatmentsToGlaucoma.join(', ').toLocaleLowerCase()) : 'Sem resposta',
    },
    {
      label: 'Visão prejudicada para alguma atividade',
      value: myInfos.impairedVision.length > 0 ? capitalize(myInfos.impairedVision.join(', ').toLocaleLowerCase()) : 'Sem resposta',
    },
    {
      label: 'Impacto no orçamento',
      value: myInfos.budgetImpact ? convertBudgetImpactToText(myInfos.budgetImpact) : 'Sem resposta',
    },
    {
      label: 'Dificuldade com o tratamento',
      value: myInfos.treatmentDifficulty.length > 0 ? capitalize(myInfos.treatmentDifficulty.join(', ').toLocaleLowerCase()) : 'Sem resposta',
    },
    {
      label: 'Medo / Preocupação',
      value: myInfos.fearWorry && myInfos.fearWorry !== [] ? myInfos.fearWorry : 'Sem resposta',
    }
  ];
}

export const getQuestionAndAnswersAboutOlhoSeco = myInfos => {
  if (!myInfos) return []
  let eyes = 'Ambos'
  if (myInfos.withEyes === 'left') {
    eyes = 'Esquerdo'
  } else if (myInfos.eyes === 'right') {
    eyes = 'Direito'
  }

  return [
    { label: 'Em quais olhos possui olho seco?', value: eyes },
    { label: 'Há quanto tempo?', value: myInfos.timeWithOlhoSeco || 'Sem resposta' },
    { label: 'Possui familiares com olho seco?', value: myInfos.familiesWithOlhoSeco.length > 0 ? capitalize(myInfos.familiesWithOlhoSeco.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Já foi submetido a alguma cirurgia ocular?', value: myInfos.olhoSecoHaveEyeSurgery && myInfos.olhoSecoHaveEyeSurgery !== [] ? myInfos.olhoSecoHaveEyeSurgery : 'Sem resposta' },
    { label: 'Utiliza regularmente algum medicamento?', value: myInfos.olhoSecoUseSomeMedicine?.length > 0 ? capitalize(myInfos.olhoSecoUseSomeMedicine.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Tratamentos atuais', value: myInfos.treatmentsToOlhoSeco?.length > 0 ? capitalize(myInfos.treatmentsToOlhoSeco.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Seu tratamento atual tem surtido efeito?', value: myInfos.olhoSecoTreatmentHasEffect && myInfos.olhoSecoTreatmentHasEffect !== [] ? myInfos.olhoSecoTreatmentHasEffect : 'Sem resposta' },
    { label: 'Já fez algum tratamento para olho seco no passado?', value: myInfos.oldTreatmentsToOlhoSeco.length > 0 ? capitalize(myInfos.oldTreatmentsToOlhoSeco.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Tem um médico que acompanha o tratamento?', value: myInfos.hasDoctorMonitoringOlhoSecoTreatment && myInfos.hasDoctorMonitoringOlhoSecoTreatment !== [] ? myInfos.hasDoctorMonitoringOlhoSecoTreatment : 'Sem resposta' },
    { label: 'Qual a sua renda mensal?', value: myInfos.olhoSecoMonthlyIncome && myInfos.olhoSecoMonthlyIncome !== [] ? myInfos.olhoSecoMonthlyIncome : 'Sem resposta' },
    { label: 'Qual o gasto mensal com o tratamento?', value: myInfos.olhoSecoMonthlyExpense && myInfos.olhoSecoMonthlyExpense !== [] ? myInfos.olhoSecoMonthlyExpense : 'Sem resposta' },
    { label: 'Qual a importância do tratamento para você?', value: myInfos.isOlhoSecoTreatmentImportant && myInfos.isOlhoSecoTreatmentImportant !== [] ? myInfos.isOlhoSecoTreatmentImportant : 'Sem resposta' },
    { label: 'Dificuldade com o tratamento', value: myInfos.treatmentDifficulty.length > 0 ? capitalize(myInfos.treatmentDifficulty.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Medo / Preocupação', value: myInfos.fearWorry && myInfos.fearWorry !== [] ? myInfos.fearWorry : 'Sem resposta' },
  ]
}

export const getQuestionAndAnswersAboutDmri = myInfos => {
  if (!myInfos) return []
  let eyes = 'Ambos'
  if (myInfos.withEyes === 'left') {
    eyes = 'Esquerdo'
  } else if (myInfos.eyes === 'right') {
    eyes = 'Direito'
  }

  return [
    { label: 'Em quais olhos possui DMRI?', value: eyes },
    { label: 'Há quanto tempo?', value: myInfos.familiesWithDmri.length > 0 ? capitalize(myInfos.familiesWithDmri.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Qual a última vez que foi ao oftalmologista?', value: myInfos.dmriLastTimeInOphthalmologist && myInfos.dmriLastTimeInOphthalmologist !== [] || 'Sem resposta' },
    { label: 'Quando foi o último exame de OCT?', value: myInfos.dmriLastOCTexam && myInfos.dmriLastOCTexam !== [] ? myInfos.dmriLastOCTexam : 'Sem resposta' },
    { label: 'Costuma monitorar a visão com a tela de Amsler?', value: myInfos.dmriMonitoringTelaDeAmsler && myInfos.dmriMonitoringTelaDeAmsler !== [] ? myInfos.dmriMonitoringTelaDeAmsler : 'Sem resposta' },
    { label: 'Tratamentos atuais', value: myInfos.treatmentsToDmri?.length > 0 ? capitalize(myInfos.treatmentsToDmri.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Quais os tipos de suplemento que você está usando no seu tratamento?', value: myInfos.dmriTreatmentSupplement?.length > 0 ? capitalize(myInfos.dmriTreatmentSupplement.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Já fez algum tratamento para DMRI no passado?', value: myInfos.oldTreatmentsToDmri.length > 0 ? capitalize(myInfos.oldTreatmentsToDmri.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Quais os tipos de suplemento que você usou em seu tratamento passado?', value: myInfos.dmriOldTreatmentSupplement?.length > 0 ? capitalize(myInfos.dmriOldTreatmentSupplement.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Tem um médico que acompanha o tratamento?', value: myInfos.hasDoctorMonitoringDmriTreatment && myInfos.hasDoctorMonitoringDmriTreatment !== [] ? myInfos.hasDoctorMonitoringDmriTreatment : 'Sem resposta' },
    { label: 'Qual a sua renda mensal?', value: myInfos.dmriMonthlyIncome && myInfos.dmriMonthlyIncome !== [] ? myInfos.dmriMonthlyIncome : 'Sem resposta' },
    { label: 'Qual o gasto mensal com o tratamento?', value: myInfos.dmriMonthlyExpense && myInfos.dmriMonthlyExpense !== [] ? myInfos.dmriMonthlyExpense : 'Sem resposta' },
    { label: 'Qual a importância do tratamento para você?', value: myInfos.isDmriTreatmentImportant && myInfos.isDmriTreatmentImportant !== [] ? myInfos.isDmriTreatmentImportant : 'Sem resposta' },
    { label: 'Dificuldade com o tratamento', value: myInfos.treatmentDifficulty.length > 0 ? capitalize(myInfos.treatmentDifficulty.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Medo / Preocupação', value: myInfos.fearWorry && myInfos.fearWorry !== [] ? myInfos.fearWorry : 'Sem resposta' },
  ]
}

export const getQuestionAndAnswersAboutUseOfGlasses = myInfos => {
  const useGlasses = myInfos.useGlasses

  const data = [
    { label: 'Você usa óculos', value: useGlasses },
  ]
  if (useGlasses === 'Sim') {
    data.push({
      label: 'Por quais motivos', value: capitalize(myInfos.glassesReasons.join(', ').toLocaleLowerCase())
    })
  }
  return data
}

export const getQuestionAndAnswersAboutOtherEyeProcedures = myInfos => {
  let didOtherEyeProcedures = myInfos.didOtherEyeProcedures

  if (!didOtherEyeProcedures) {
    didOtherEyeProcedures = 'Sem resposta'
  }
  const data = [
    { label: 'Você já fez algum outro procedimento ocular?', value: didOtherEyeProcedures },
  ]
  if (didOtherEyeProcedures === 'Sim') {
    data.push({
      label: 'Procedimentos', value: capitalize(myInfos.otherEyeProceduresReasons.join(', ').toLocaleLowerCase())
    })
  }
  return data
}

export const getQuestionAndAnswersAboutHabitsOfLife = myInfos => {
  let physicalExercise = myInfos.physicalExercise

  if (physicalExercise === 'Sim' && myInfos.physicalExercise === 'Sim') {
    physicalExercise = '+150min por semana'
  } else if (physicalExercise === 'Sim' && myInfos.physicalExercise !== 'Sim') {
    physicalExercise = '-150min por semana'
  } else if (!physicalExercise) {
    physicalExercise = 'Sem resposta'
  } else {
    physicalExercise = 'Não'
  }

  let drinkCofee = myInfos.drinkCoffee

  if (drinkCofee === 'Sim' && myInfos.coffeeQuantity) {
    drinkCofee = `Sim, ${myInfos.coffeeQuantity} por dia`
  } else if (!drinkCofee) {
    drinkCofee = 'Sem resposta'
  }

  let smokeCigarettes = myInfos.smokeCigarettes

  if (smokeCigarettes === 'Sim' && myInfos.cigarettesQuantity) {
    smokeCigarettes = `Sim, ${myInfos.cigarettesQuantity} por dia`
  } else if (!smokeCigarettes) {
    smokeCigarettes = 'Sem resposta'
  }

  let { sleepOver6Hours } = myInfos

  if (sleepOver6Hours === 'Sim') {
    sleepOver6Hours = 'Durmo mais de 6h por dia'
  } else if (sleepOver6Hours === 'Não') {
    sleepOver6Hours = 'Não durmo mais de 6h por dia'
  } else {
    sleepOver6Hours = 'Sem resposta'
  }
  return [
    { label: 'Peso', value: myInfos.bodyWeight || 'Sem resposta' },
    { label: 'Altura', value: myInfos.bodyHeight ? `${(myInfos.bodyHeight / 100).toFixed(2).replace('.', ',')}m` : 'Sem resposta' },
    { label: 'Exercícios físicos', value: physicalExercise },
    { label: 'Café', value: drinkCofee },
    { label: 'Bebida alcoólicas', value: myInfos.drinkAlcohol || 'Sem resposta' },
    { label: 'Uso de cigarros', value: smokeCigarettes },
    { label: 'Uso de maconha', value: myInfos.useWeeds || 'Sem resposta' },
    { label: 'Sono', value: sleepOver6Hours },
  ]
}

export const getQuestionAndAnswersAboutOtherDiseases = myInfos => {
  let diseases = myInfos.hasOtherDiseases;
  if (myInfos.hasOtherDiseases === 'Não') {
    diseases = 'Não tenho outras doenças'
  }
  diseases = diseases === 'Sim' && diseases !== 'Não' ? capitalize(myInfos.hasOtherDiseases.join(', ').toLocaleLowerCase()) : 'Sem resposta'
  return [
    { label: 'Doenças', value: diseases }
  ]
}

export const getQuestionAndAnswersAboutOtherEyeDiseases = myInfos => {
  let eyeDiseases = myInfos.hasOtherEyeDiseases;
  if (myInfos.hasOtherEyeDiseases === 'Não') {
    eyeDiseases = 'Não tenho outras doenças nos olhos'
  }
  eyeDiseases = eyeDiseases === 'Sim' && eyeDiseases !== 'Não' ? capitalize(myInfos.hasOtherEyeDiseases.join(', ').toLocaleLowerCase()) : 'Sem resposta'
  return [
    { label: 'Doenças nos olhos', value: eyeDiseases }
  ]
}

export const getQuestionAndAnswersAboutMedicationEveryDay = myInfos => {
  let medications = myInfos.hasOtherEyeDiseases;
  if (myInfos.hasOtherEyeDiseases === 'Não') {
    medications = 'Não faço uso de medicamentos'
  }
  medications = medications === 'Sim' && medications !== 'Não' ? capitalize(myInfos.hasOtherMedicationEveryDay.join(', ').toLocaleLowerCase()) : 'Sem resposta'
  return [
    { label: 'Medicamentos que uso', value: medications }
  ]
}

export const getQuestionAndAnswersAboutGenericDisease = myInfos => {
  if (!myInfos) return []
  let eyes = 'Ambos'
  if (myInfos.withEyes === 'left') {
    eyes = 'Esquerdo'
  } else if (myInfos.eyes === 'right') {
    eyes = 'Direito'
  }

  const { disease } = myInfos
  return [
    { label: `Em quais olhos possui ${disease}`, value: eyes },
    { label: 'Há quanto tempo?', value: myInfos.timeWithGenericDisease || 'Sem resposta' },
    { label: `Possui familiares com ${disease}?`, value: myInfos.familiesWithDisease.length > 0 ? capitalize(myInfos.familiesWithDisease.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Tem um médico que acompanha o tratamento?', value: myInfos.hasDoctorMonitoringGenericDiseaseTreatment && myInfos.hasDoctorMonitoringGenericDiseaseTreatment !== [] ? myInfos.hasDoctorMonitoringGenericDiseaseTreatment : 'Sem resposta' },
    { label: 'Qual a sua renda mensal?', value: myInfos.genericDiseaseMonthlyIncome && myInfos.genericDiseaseMonthlyIncome !== [] ? myInfos.genericDiseaseMonthlyIncome : 'Sem resposta' },
    { label: 'Qual o gasto mensal com o tratamento?', value: myInfos.genericDiseaseMonthlyExpense && myInfos.genericDiseaseMonthlyExpense !== [] ? myInfos.genericDiseaseMonthlyExpense : 'Sem resposta' },
    { label: 'Qual a importância do tratamento para você?', value: myInfos.isGenericDiseaseTreatmentImportant && myInfos.isGenericDiseaseTreatmentImportant !== [] ? myInfos.isGenericDiseaseTreatmentImportant : 'Sem resposta' },
    { label: 'Dificuldade com o tratamento', value: myInfos.treatmentDifficulty.length > 0 ? capitalize(myInfos.treatmentDifficulty.join(', ').toLocaleLowerCase()) : 'Sem resposta' },
    { label: 'Medo / Preocupação', value: myInfos.fearWorry && myInfos.fearWorry !== [] ? myInfos.fearWorry : 'Sem resposta' }
  ]
}

export const getQuestionAndAnswersAboutAlergia = myInfos => {
  if (!myInfos) return []
  const eyeDropAllergiesData = myInfos?.eyeDropAllergies;
  const hasEyeDropsAllergies = myInfos?.hasEyeDropsAllergies || eyeDropAllergiesData?.length ? 'Sim' : 'Não';

  const questions = [
    { label: 'Você tem alergia a algum colírio?', value: hasEyeDropsAllergies || 'Sem resposta' },
  ]

  eyeDropAllergiesData.map(item => {
    return [
      questions.push({ label: `Qual o nome do colírio?`, value: item.eyeDrop?.medicine ?? item?.medicine }),
      questions.push({ label: `Quais sintomas você apresentou durante o uso do colírio?`, value: item.symptons.join(', ') })
    ]
  })
  return questions
}
