<template>
  <div id="additional-info">
    <CardHeader
      title="Informações registradas"
      :showPeriod="false"
      :options="typeInformations"
      @changeOption="changeTypeInformation"
      class="mb-3"
    >
      <v-generic-filter
        :options="options"
        @changeFilter="changeOption"
        class="generic-filter first-filter"
      />
    </CardHeader>
    <perfect-scrollbar ref="scroll">
      <div id="additional-info-data" ref="additionalInfoData">
        <v-additional-info-card
          v-if="hasGlaucoma && showOcularHealth"
          title="Sobre Glaucoma"
          info="aboutGlaucoma"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <v-additional-info-card
          v-if="hasOlhoSeco && showOcularHealth"
          title="Sobre Olho Seco"
          info="aboutOlhoSeco"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <v-additional-info-card
          v-if="hasDmri && showOcularHealth"
          title="Sobre DMRI"
          info="aboutDmri"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <section v-if="showOcularHealth">
          <v-additional-info-card
            v-for="(disease, index) in myInfos?.aboutGenericDiseasesTreatments"
            :key="index"
            :index="index"
            :title="`Sobre ${disease.disease}`"
            info="aboutGenericDiseasesTreatments"
            :myInfos="myInfos"
            :showCompletedOption="option"
          />
        </section>

        <v-additional-info-card
          v-if="showOcularHealth"
          title="Uso de óculos"
          info="useOfGlasses"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <v-additional-info-card
          v-if="showOcularHealth"
          title="Outras doenças nos olhos"
          info="otherEyeDiseases"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <v-additional-info-card
          v-if="showOcularHealth"
          title="Outros procedimentos nos olhos"
          info="otherEyeProcedures"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <v-additional-info-card
          v-if="showOcularHealth"
          title="Alergia a colírios"
          info="eyeDropAllergies"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <v-additional-info-card
          v-if="showGeneralHealth"
          title="Hábitos de vida e como estou"
          info="habitsOfLife"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <v-additional-info-card
          v-if="showGeneralHealth"
          title="Medicamentos que utilizo"
          info="medicationEveryDay"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />

        <v-additional-info-card
          v-if="showGeneralHealth"
          title="Outras doenças"
          info="otherDiseases"
          :myInfos="myInfos"
          :showCompletedOption="option"
        />
      </div>

      <v-no-filters-results
        v-if="!childCount"
        message="Nenhuma informação registrada"
      />
    </perfect-scrollbar>
  </div>
</template>

<script>
import CardHeader from '@/layouts/InvoicingTiss/Dashboard/CardHeader'
import AdditionalInfoCard from '@/components/Glauco/Dashboard/Patient/AdditionalInfoCard'
import GenericMultiselectFilter from '@/components/General/GenericMultiselectFilter'
import NoFiltersResults from '@/components/General/noFilterResults'

export default {
  name: 'AdditionalInfo',
  components: {
    CardHeader,
    'v-additional-info-card': AdditionalInfoCard,
    'v-generic-filter': GenericMultiselectFilter,
    'v-no-filters-results': NoFiltersResults
  },
  data() {
    return {
      typeInformation: null,
      typeInformations: [
        { label: 'Selecionar', value: null },
        { label: 'Saúde ocular', value: 'saude-ocular' },
        { label: 'Saúde geral', value: 'saude-geral' }
      ],
      options: [
        { label: 'Selecionar', value: null },
        { label: 'Informações preenchidas', value: 'informacoes-preenchidas' },
        {
          label: 'Informações não preenchidas',
          value: 'informacoes-nao-preenchidas'
        }
      ],
      myInfos: {},
      patientId: this.$route.params.patientId,
      option: null,
      childCount: 0
    }
  },
  computed: {
    hasGlaucoma() {
      return Boolean(this.myInfos?.aboutGlaucoma)
    },
    hasOlhoSeco() {
      return Boolean(this.myInfos?.aboutOlhoSeco)
    },
    hasDmri() {
      return Boolean(this.myInfos.aboutDmri)
    },
    showOcularHealth() {
      return this.typeInformation === 'saude-ocular' || !this.typeInformation
    },
    showGeneralHealth() {
      return this.typeInformation === 'saude-geral' || !this.typeInformation
    }
  },
  async created() {
    await this.getPatientAdditionalInfo()
    await this.getChildCount()
  },
  methods: {
    async getPatientAdditionalInfo() {
      try {
        const { data } = await this.api.getPatientAdditionalInfo(this.patientId)
        this.$set(this.myInfos, 'aboutGlaucoma', data.aboutGlaucoma)
        this.$set(this.myInfos, 'aboutOlhoSeco', data.aboutOlhoSeco)
        this.$set(this.myInfos, 'aboutDmri', data.aboutDmri)
        this.$set(this.myInfos, 'useOfGlasses', data.useOfGlasses)
        this.$set(this.myInfos, 'otherEyeDiseases', data.otherEyeDiseases)
        this.$set(this.myInfos, 'otherEyeProcedures', data.otherEyeProcedures)
        this.$set(this.myInfos, 'eyeDropAllergies', data.eyeDropAllergies)
        this.$set(this.myInfos, 'habitsOfLife', data.habitsOfLife)
        this.$set(this.myInfos, 'medicationEveryDay', data.medicationEveryDay)
        this.$set(this.myInfos, 'otherDiseases', data.otherDiseases)
        this.$set(
          this.myInfos,
          'aboutGenericDiseasesTreatments',
          data.aboutGenericDiseasesTreatments
        )
        console.log(this.myInfos)
      } catch (error) {
        this.$toast.error('Erro ao carregar informações adicionais')
      }
    },
    async resolveChildCount() {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(this.$refs.additionalInfoData?.childElementCount)
        })
      })
    },
    async getChildCount() {
      this.childCount = await this.resolveChildCount()
    },
    async changeOption(option) {
      this.option = option.value
      await this.getChildCount()
    },
    async changeTypeInformation(option) {
      this.typeInformation = option.value
      await this.getChildCount()
    }
  }
}
</script>

<style lang="scss">
#additional-info {
  padding: 30px 24px;
  margin: 0 auto;
  background-color: var(--neutral-000);
  border-radius: 8px;
  margin-top: 2%;

  .title {
    font-weight: 700;
    font-family: 'Red Hat Display';
    font-size: min(1.2vw, 16px);
  }

  #additional-info-data {
    max-height: 500px;
  }
}
</style>
