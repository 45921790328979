<template>
  <b-container v-if="showCompletedComputed">
    <b-row
      class="additional-info-card justify-content-center align-items-center"
      v-if="hasInfo"
    >
      <b-col cols="1">
        <component :is="icon" />
      </b-col>
      <b-col cols="7">
        <p class="title">{{ title }}</p>
      </b-col>
      <b-col cols="2">
        <small
          class="usage"
          :class="{ complete: isCompleted, incomplete: !isCompleted }"
        >
          {{ myInfoStatus }}
        </small>
      </b-col>
      <b-col cols="2">
        <v-chevron-down class="chevron" v-b-toggle="`collapse-${info}`" />
      </b-col>
    </b-row>
    <b-collapse :id="`collapse-${info}`" class="collapse-wrapper">
      <b-container class="additional-info-card container">
        <b-row
          class="align-items-center"
          v-for="(question, index) in questionAndAnswers"
          :key="index"
        >
          <b-col class="question" cols="12">
            <p class="mb-2">
              {{ question.label }} -
              <span class="answer">{{ question.value }}</span>
            </p>
            <div class="dotted-border" />
          </b-col>
        </b-row>
      </b-container>
    </b-collapse>
  </b-container>
</template>

<script>
import Glasses from '@/assets//icons/glauco/glasses.svg'
import Medicamentos from '@/assets/icons/glauco/medicamentos.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Doenca from '@/assets/icons/glauco/doenca.svg'
import DoencaOlho from '@/assets/icons/glauco/doenca-olho.svg'
import HabitsOfLife from '@/assets/icons/glauco/habits-of-life.svg'
import AlergiaIcon from '@/assets/icons/glauco/alergia.svg'

import {
  getMyInfosStatus,
  getQuestionAndAnswersAboutGlaucoma,
  getQuestionAndAnswersAboutOlhoSeco,
  getQuestionAndAnswersAboutUseOfGlasses,
  getQuestionAndAnswersAboutOtherEyeProcedures,
  getQuestionAndAnswersAboutHabitsOfLife,
  getQuestionAndAnswersAboutOtherDiseases,
  getQuestionAndAnswersAboutOtherEyeDiseases,
  getQuestionAndAnswersAboutMedicationEveryDay,
  getQuestionAndAnswersAboutDmri,
  getQuestionAndAnswersAboutGenericDisease,
  getQuestionAndAnswersAboutAlergia
} from '@/utils/glaucoPatientInfoUtils'

export default {
  name: 'AdditionalInfoCard',
  components: {
    'v-chevron-down': ChevronDown
  },
  props: {
    title: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: true
    },
    myInfos: {
      type: Object,
      default: () => ({})
    },
    showCompletedOption: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    hasInfo() {
      return this.myInfos?.[this.info]
    },
    myInfoStatus() {
      return getMyInfosStatus(this.myInfos, this.info)
    },
    isCompleted() {
      if (!this.hasInfo) return false
      const splited = this.myInfoStatus.split('/')
      return parseInt(splited[0]) === parseInt(splited[1])
    },
    icon() {
      return (
        {
          useOfGlasses: Glasses,
          medicationEveryDay: Medicamentos,
          otherDiseases: Doenca,
          aboutGlaucoma: DoencaOlho,
          aboutOlhoSeco: DoencaOlho,
          aboutDmri: DoencaOlho,
          otherEyeProcedures: DoencaOlho,
          aboutGenericDiseasesTreatments: DoencaOlho,
          habitsOfLife: HabitsOfLife,
          eyeDropAllergies: AlergiaIcon
        }[this.info] || Glasses
      )
    },
    questionAndAnswers() {
      if (!this.hasInfo) return []
      return (
        {
          aboutGlaucoma: getQuestionAndAnswersAboutGlaucoma(
            this.myInfos?.aboutGlaucoma
          ),
          aboutOlhoSeco: getQuestionAndAnswersAboutOlhoSeco(
            this.myInfos?.aboutOlhoSeco
          ),
          useOfGlasses: getQuestionAndAnswersAboutUseOfGlasses(
            this.myInfos?.useOfGlasses
          ),
          otherEyeProcedures: getQuestionAndAnswersAboutOtherEyeProcedures(
            this.myInfos?.otherEyeProcedures
          ),
          habitsOfLife: getQuestionAndAnswersAboutHabitsOfLife(
            this.myInfos?.habitsOfLife
          ),
          otherDiseases: getQuestionAndAnswersAboutOtherDiseases(
            this.myInfos?.otherDiseases
          ),
          otherEyeDiseases: getQuestionAndAnswersAboutOtherEyeDiseases(
            this.myInfos?.otherEyeDiseases
          ),
          medicationEveryDay: getQuestionAndAnswersAboutMedicationEveryDay(
            this.myInfos?.medicationEveryDay
          ),
          aboutDmri: getQuestionAndAnswersAboutDmri(this.myInfos?.aboutDmri),
          aboutGenericDiseasesTreatments:
            getQuestionAndAnswersAboutGenericDisease(
              this.myInfos?.aboutGenericDiseasesTreatments[this.index]
            ),
          eyeDropAllergies: getQuestionAndAnswersAboutAlergia(
            this.myInfos?.eyeDropAllergies
          )
        }[this.info] || []
      )
    },
    showCompletedComputed() {
      if (this.showCompletedOption === 'informacoes-preenchidas') {
        return this.isCompleted
      } else if (this.showCompletedOption === 'informacoes-nao-preenchidas') {
        return !this.isCompleted
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.question {
  padding: 10px;
  p {
    font-weight: 600;
    color: var(--type-active);
    font-size: min(1.2vw, 16px);

    .answer {
      color: var(--blue-500);
    }
  }

  .dotted-border {
    border-bottom: 1px dashed var(--neutral-300);
  }
}
.additional-info-card {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 10px;
  width: 97%;
  margin: 0 auto;
  margin-bottom: 1%;

  &.container {
    margin-top: -1% !important;
    background-color: var(--neutral-200);
  }

  .title {
    color: var(--type-active);
    font-weight: 600;
    font-size: min(1.2vw, 16px);
  }

  .usage {
    padding: 7px;
    font-weight: 700;
    color: var(--type-active);
    border-radius: 16px;
    width: fit-content;
    font-size: 12px;

    &.complete {
      background-color: var(--states-success-soft-green);
    }

    &.incomplete {
      background-color: var(--light-orange-200);
    }
  }

  .chevron {
    height: 25px;
    width: 25px;
    svg {
      stroke: var(--dark-blue);
    }
    &.not-collapsed {
      animation: rotateUp 0.5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }
    &.collapsed {
      animation: rotateDown 0.5s;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
    }

    @keyframes rotateUp {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes rotateDown {
      0% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
  .chevron:focus {
    outline: none !important;
  }

  &.question-card {
    margin-bottom: 0 !important;
  }
}
</style>
