<template>
  <multiselect
    track-by="label"
    label="label"
    v-model="option"
    :options="options"
    :showLabels="false"
    :searchable="true"
    :allow-empty="false"
    :placeholder="optionPlaceholder"
    @select="value => $emit('changeFilter', value)"
  >
    <template slot="caret">
      <ChevronDown class="chevron" />
    </template>
    <template slot="singleLabel" slot-scope="{ option }">
      <span class="d-inline-block text-truncate" style="width: 80%">
        {{ option.label }}
      </span>
    </template>
    <template slot="noOptions"> Nenhuma opção </template>
    <template slot="noResult"> Nenhum resultado </template>
  </multiselect>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  name: 'MedicationFilter',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    optionPlaceholder: {
      type: String,
      default: 'Selecionar...'
    }
  },
  data() {
    return {
      option: null
    }
  },
  components: {
    ChevronDown
  }
}
</script>
